
<template>
  <div class="table">
    <div class="table-bar">
      <el-input placeholder="Search target..." v-model="searchInput" prefix-icon="search" class="search-input"
        style="width: 300px" />
      <el-pagination id="el-pagination" v-model:currentPage="currentPage" :page-size="pageSize" :pager-count="9"
        :page-count="pageCount" background :total="filterData.length" layout="total, prev, pager, next, jumper"
        @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <el-table :data="
      filterData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
    " stripe border style="width: 100%" @sort-change="handleSortChange">
      <el-table-column prop="smile" label="Smiles" width="300" />
      <el-table-column prop="test_auc" label="Molecule">
        <template #default="scope">
          <img class="image" :src="scope.row.img" alt="Loading">
        </template>
      </el-table-column>
      <el-table-column prop="score" label="Score" width="200" />
    </el-table>
  </div>
</template>

<script>
export default {
  props: ["data"],
  emits: ['getCurrentPage'],
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      searchInput: "",
    };
  },

  computed: {
    resultData() {
      return this.data;
    },

    //根据页面大小计算总页数
    pageCount() {
      return Math.ceil(this.filterData.length / this.pageSize);
    },
    filterData() {
      let input = this.searchInput && this.searchInput.toLowerCase();
      if (input == "") return this.resultData;
      else {
        console.log("resultData", this.resultData)
        return this.resultData.filter((item) => {
          return item.smile.toLowerCase().match(input);
        });
      }
    },
  },
  methods: {
    getCurrentPage() {
      this.$emit("getCurrentPage", this.currentPage)
    },
    showDetail(e) {
      console.log(e)
    },
    handleClick(e) {
      console.log(e);
      //   this.$router.push({
      //     path: "/service/result-detail",
      //     query: {
      //       id: 123,
      //       obj: JSON.stringify({
      //         //将需要的信息传递给detail显示页面
      //         targetname: e.name,
      //         smilesInfo: this.smilesInfo[this.moleculeName],
      //         mode: this.mode,
      //         moleculeName: this.moleculeName,
      //       }),
      //     },
      //   });
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      // 处理页面下标改变
      this.currentPage = val;
      this.getCurrentPage();
    },

    /**默认的排序只针对当前页，所以要重写处理分页后的表格排序
     * 1. String： 按字典序
     * 2. Number： 按数指大小
     */
    handleSortChange(target) {
      if (target.prop == "name") {
        // 实际数据中只有targetName为String，所以单独处理
        if (target.order == "ascending") {
          this.resultData = this.resultData.sort((a, b) => {
            return a[target.prop] < b[target.prop] ? -1 : 1;
          });
        } else if (target.order == "descending") {
          this.resultData.sort((a, b) => {
            return a[target.prop] > b[target.prop] ? -1 : 1;
          });
        }
      } else {
        if (target.order == "ascending") {
          this.resultData = this.resultData.sort((a, b) => {
            return a[target.prop] - b[target.prop];
          });
        } else if (target.order == "descending") {
          this.resultData.sort((a, b) => {
            return b[target.prop] - a[target.prop];
          });
        }
      }
    },
  },
};
</script>

<style>
.table {
  width: 1100px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.table-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.search-input {
  font-size: 15px;
}

#el-pagination .el-pagination__total,
#el-pagination .el-pagination__jump {
  font-size: 15px;
  color: white;
  /* font-weight: li */
}

#el-pagination .el-pager .number.active {
  background: var(--color2-dark);
}

#el-pagination .el-pager .number:hover,
#el-pagination .btn-prev.hover,
#el-pagination .btn-next.hover {
  background: var(--color2-light);
  color: var(--color1-dark);
}

.el-table__header-wrapper .el-table__cell {
  font-size: 12pt;
  color: var(--color1-dark);
}

.el-table__body .el-table__row,
.el-table__body .el-table__row:hover {
  background: var(--color2-light);
  font-size: 12pt;
}

.el-table .cell {
  text-align: center;
}

.detail-btn {
  font-size: 12pt;
  border-radius: 5px;
  background: none;
  outline: none;
  padding: 5px;
  border: 1px solid var(--color2-dark);
  cursor: pointer;
}

.image {
  height: 200px;
}

.detail-btn:hover {
  background: var(--color2-light);
}
</style>